import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Paper } from '@mui/material';
import { Button } from '@mui/material';
import { styles } from './styles.js';
import { DialogInfo, FormError, IsEmpty, Line, Loading, TextInput } from './items.js';
import { faAngleLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';
import { nonEmail } from './items.js';


export const Support = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(true);
    const [isSending, setSending] = useState(false);

    // PROJECT
    const [project, setProject] = useState([]);
    const [language, setLanguage] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [theme, setTheme] = useState([]);
    const [pages, setPages] = useState([]);

    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [mobil, setMobil] = useState('');
    const [message, setMessage] = useState('');
    const [messageErr, setMessageErr] = useState(false);

    const [error, setError] = useState('');
    const [showSended, setShowSended] = useState(false);

    const [recaptcha, setRecaptcha] = useState('');

    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const logo = require('./react/app/datadream.png');

    const [height, setHeight] = useState(window.innerHeight);

    const formWidth = 450;
    const offset = 20;

    let params = useParams();

    var isRunning = false;
    useEffect(() => {

        if (isRunning == false) {
            isRunning = true;
            db_get();
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'project', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    project_tid: params.project,
                    language_tid: params.language
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setProject(json.project);
                setLanguage(json.language);
                setTheme(json.theme);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    /* *******************************************************************************
        POSLAT SPRAVU
    ******************************************************************************* */
    const db_send = async () => {
        setSending(true);
        try {
            const response = await fetch(
                global.db_url + 'message_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    mobil: mobil,
                    email: email,
                    message: message,
                    app: params.project
                })
            })

            const json = await response.json();

            setSending(false);
            if (json.ok > 0) {
                setBusy(false);
                setShowSended(true);
                setName('');
                setMobil('');
                setEmail('');
                setMessage('');

            } else {
                setError(language.message_error);
            }

        } catch (error) {
            setSending(false);
            setError('DB_error');
        }
    }

    const GoBack = () => {
        let link = '/guide/' + params.project + '/' + params.language;
        navigate(link, { replace: true });
    }


    const onChangeCaptcha = (value) => {
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    const Send = () => {
        var error = 0;
        setError('');

        var error = false;
        error += IsEmpty(name) == true ? setNameErr(true) ?? true : setNameErr(false) ?? false;
        error += IsEmpty(message) == true ? setMessageErr(true) ?? true : setMessageErr(false) ?? false;
        error += nonEmail(email) == true ? setEmailErr(true) ?? true : setEmailErr(false) ?? false;

        if (error > 0) {
            setError(language.required);
        } else {
            if (recaptcha != '') {
                db_send();
            }
        }
    }

    const DialogResult = () => {
        setShowSended(false);
    }

    return (
        <div style={{ ...styles.Block, paddingBottom: 80 }}>
            <div style={{ ...styles.Block, backgroundColor: theme.background_intro, paddingTop: 30, paddingBottom: 30 }}>
                <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                    <p style={{ ...styles.TextNormal, marginTop: 0, color: theme.color_text, fontWeight: '600' }}>{project.name}</p>
                    <p style={{ ...styles.TextTiny, marginTop: 3, color: theme.color_text, fontWeight: '400' }}>{language.guide}</p>
                    <div style={{ ...styles.Block }}>
                        <Line color={global.theme_light_gray} margin={20} />
                    </div>
                    <p style={{ ...styles.TextXLarge, color: theme.color_text, fontWeight: '500' }}>{language.support}</p>

                    <div onClick={() => GoBack(true)} style={{ ...styles.BlockRowRaw, cursor: 'pointer', marginTop: 20 }}>
                        <FontAwesomeIcon style={{ height: 22, marginTop: 5, color: theme.color_link }} icon={faAngleLeft} />
                        <p style={{ ...styles.TextLarge, marginLeft: 10, color: theme.color_link, fontWeight: '400' }}>{language.content}</p>
                    </div>
                </div>
            </div >

            <div style={{ ...styles.Block, backgroundColor: theme.background_page, paddingTop: 30, paddingBottom: 30 }}>
                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 120, maxHeight: 100, objectFit: 'contain' }}></img>
                <p style={{ ...styles.TextTiny, marginTop: 10, color: theme.color_text, fontWeight: '600' }}>{global.company.name}</p>
                <p style={{ ...styles.TextTiny, marginTop: 10, color: theme.color_text, fontWeight: '400' }}>{language.mobil}: {global.company.mobil1}</p>
                <p style={{ ...styles.TextTiny, color: theme.color_text, fontWeight: '400' }}>e-mail: {global.company.email}</p>
            </div>

            <div style={{ ...styles.Block, backgroundColor: theme.background_page, paddingTop: 30, paddingBottom: 30 }}>
                <Paper elevation={3} style={{ ...styles.Block, maxWidth: formWidth, paddingTop: 20, paddingBottom: 20, borderRadius: 5 }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <p style={{ ...styles.TextNormal, color: theme.color_text, fontWeight: '400' }}>{language.form}</p>
                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                            <div style={{ ...styles.Block }}>
                                <TextInput error={nameErr} redraw radius={5} enabled={true} value={name} label={language.name_surname + ' (*)'} func={(txt) => { setName(txt) }} />
                            </div>
                            <div style={{ ...styles.Block, marginTop: offset }}>
                                <TextInput redraw radius={5} enabled={true} value={mobil} label={language.mobil + ' (*)'} func={(txt) => { setMobil(txt) }} />
                            </div>
                            <div style={{ ...styles.Block, marginTop: offset }}>
                                <TextInput error={emailErr} redraw radius={5} enabled={true} value={email} label={'e-mail (*)'} func={(txt) => { setEmail(txt) }} />
                            </div>
                            <div style={{ ...styles.Block, marginTop: offset }}>
                                <TextInput error={messageErr} redraw rows={6} radius={5} enabled={true} value={message} label={language.message + ' (*)'} func={(txt) => { setMessage(txt) }} />
                            </div>
                            <div style={{ ...styles.Block, width: '95%', marginTop: 20 }}>
                                <ReCAPTCHA
                                    sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                                    onChange={(value) => onChangeCaptcha(value)}
                                />
                            </div>
                            <FormError error={error} />
                            <div style={{ ...styles.Block, marginTop: offset }}>
                                <Button onClick={() => Send()} style={{ ...styles.ButtonDark }}>{language.send}</Button>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>

            {showSended == true ?
                <DialogInfo message={language.message_ok} backcolor={true} icon={faInfoCircle} background={false} func={DialogResult.bind(this)} />
                : null}

            {isSending == true ?
                <Loading center={true} />
                : null}

            {isBusy == true ?
                <Loading center={true} />
                : null}

        </div>
    )
}