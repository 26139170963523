import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './globals.js';
import { Main } from './Main';
import { NotFound } from './notfound';
import { styles } from './styles';
import { Guide } from './Guide';
import { Page } from './Page';
import { Support } from './support';

function App() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {

        // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);

    }, [])


    return (
        <div className="App" style={{ ...styles.BlockCenter, minHeight: height, backgroundColor: global.theme_white, fontFamily: 'Noto+Sans', WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />

            <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&display=swap" rel="stylesheet" />
            <Routes>
                {/*
                <Route path="/" element={<Main />} />
                */}
                <Route path="/" element={<NotFound />} />
                <Route path="/guide/:project/:language" element={<Guide />} />
                <Route path="/guide/:project/:language/:page" element={<Page />} />
                <Route path="/guide/:project/:language/:page/:tiny" element={<Page />} />
                <Route path="/guide/:project/:language/:page/:tiny/:anchor" element={<Page />} />
                <Route path="/guide/support/:project/:language" element={<Support />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;
