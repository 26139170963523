import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import { faAsterisk, faEdit, faFaceFrown, faFileLines, faList, faPeace, faPen, faPrint, faTableCells, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';



export const NotFound = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const navigate = useNavigate();
    let params = useParams();

    useEffect(() => {

        return () => {
        };

    }, []);
    return (
        <div style={{ ...styles.Block, height:height }}>
            <FontAwesomeIcon style={{ width: 60, height: 60, color: global.theme_red }} icon={faTriangleExclamation} />
            <p style={{ ...styles.TextLarge, marginTop:20 }}>Stránka nie je k dispozícii !</p>
        </div>
    )

};

