/*

    DATABASE: xt034400

*/
import { faList } from '@fortawesome/free-solid-svg-icons'

global.testing = false;


// RELEASE VERZIA PRE PETKA
global.release = false;

global.max_screen = 1000;
global.max_content = 800;
global.app_name = 'Datadream app';

// DATABAZA
global.web = global.testing == true ? 'http://localhost:3000' : 'https://support.datadream.sk';
global.web_images = 'https://support.datadream.sk';
global.db_url = 'https://support.datadream.sk/app_web/';

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

global.radius = 20;

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_transparent = '#00000000';

global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';

global.theme_lighter = '#BBD6FD';
global.theme_light = '#95B2DC';
global.theme_medium = '#5F7AA1';
global.theme_dark = '#3D5273';
global.theme_darker = '#29364A';

global.theme_lighteen_blue = '#E2ECFF';
global.theme_lighter_blue = '#92BBEC';
global.theme_light_blue = '#2A8EFF';
global.theme_blue = '#205CCA';
global.theme_dark_blue = '#003BA4';
global.theme_darker_blue = '#041A47';

global.theme_lighteen_red = '#FFE4E2';
global.theme_lighter_red = '#F99891';
global.theme_light_red = '#F77268';
global.theme_red = '#F44336';
global.theme_dark_red = '#C3170B';

global.theme_lighteen_green = '#E2FFE3';
global.theme_light_green = '#A6FA9D';
global.theme_green = '#46F436';
global.theme_dark_green = '#18BB18';
global.theme_darker_green = '#15A115';

global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#F9F8F8';
global.theme_grayer = '#f7f7f7';
global.theme_xxgray = '#CCCCCC';
global.theme_dark_gray = '#666666';
global.theme_darker_gray = '#333333';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_lighteen_violet = '#E9E2FF';
global.theme_violet = '#BB00BB';
global.theme_brown = 'brown';
global.theme_logo_background = '#D0DDEF';


// ******************************************************************************************
// GRADIENTS
// ******************************************************************************************
global.theme_back_gradient = 'linear-gradient(to bottom,#555555,#999999)';
global.theme_bar_gradient = 'linear-gradient(to bottom,#0C0C50 0%,#205CCA 50%)';
global.theme_titlebar_gradient = 'linear-gradient(to right,#041A47FF,#041A47BB)';
global.theme_dialog_background = 'linear-gradient(220deg, #FFFFFF 0%, #92BBEC 66%)';
global.theme_window_background = 'linear-gradient(220deg, #FFFFFFAA 0%, #92BBECAA 66%)';
global.theme_subwindow_background = 'linear-gradient(220deg, #67B8FD77 0%, #9FDAFDAA 66%)';
global.theme_button_dark = 'linear-gradient(180deg, #3D5273, #29364A)';
global.theme_leftmenu_background = 'linear-gradient(220deg, #92BBEC 0%, #92BBEC 66%)';
global.theme_line = 'linear-gradient(220deg, #5F7AA100 0%, #5F7AA1FF 50%, #5F7AA100 100%)';

// ******************************************************************************************
// DIALOG
// ******************************************************************************************
global.dialog_bar_height = 80;
global.dialog_icon_size = 42;
global.dialog_bar_color = 'linear-gradient(to right,#205CCA20,#0C346B10)';
global.window_bar_color = 'linear-gradient(to right,#FFFFFF50,#0C346B50)';


// ******************************************************************************************
// IKONY
// ******************************************************************************************
global.icon_background = 'linear-gradient(220deg,#0C346B,#205CCA)';
global.shortcut_background = 'linear-gradient(220deg,#0C346B,#205CCA)';
global.icon_wite_background = 'linear-gradient(315deg,#FFFFFF,#88BBFF)';
global.shortcut_green_background = 'linear-gradient(220deg,#007700,#15A115)';
global.shortcut_red_background = 'linear-gradient(220deg,#C3170B,#F44336)';

// ******************************************************************************************
// MENU
// ******************************************************************************************
global.menu_width = 260;
global.statusbar_height = 40;
global.topmenu_height = 50;
global.menutitle_height = 150;
global.menu_elevation = 3;
global.theme_menu_selected = '#015FBD';

// ******************************************************************************************
// PISMO
// ******************************************************************************************
global.font_xxtiny = 12;
global.font_xtiny = 13;
global.font_tiny = 16;
global.font_small = 18;
global.font_normal = 22;
global.font_large = 26;
global.font_xlarge = 32;
global.font_xxlarge = 40;
global.font_table = 14;


// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;


// **********************************************************************
// COMPANY - UDAJE O SPOLOCNOSTI
// **********************************************************************
global.company = {
    name: 'Datadream s.r.o.',
    street: 'Viničná 52/16',
    psc: '951 93',
    town: 'Machulince',
    state: 'Slovensko',
    mobil1: '0905 - 668 294',
    mobil2: '0944 - 411 144',
    call: '0905 - 668 294',
    email: 'info@datadream.sk',
    ico: '54084253',
    dic: '2121566524',
    district: 'Zlaté Moravce',
};


// **********************************************************************
// TYPY FARIEB
// **********************************************************************
global.color_table = [
    { id: 0, color: '#000000' },
    { id: 1, color: '#777777' },
    { id: 2, color: '#CCCCCC' },
    { id: 3, color: '#F4F5FA' },
    { id: 4, color: '#FFF1D7' },
    { id: 5, color: '#FFDCD7' },
    { id: 6, color: '#FFD7F3' },
    { id: 7, color: '#DFD7FF' },

    { id: 8, color: '#0000AA' },
    { id: 9, color: '#0000FF' },
    { id: 10, color: '#509DF4' },
    { id: 11, color: '#FF00FF' },
    { id: 12, color: '#D7EFFF' },
    { id: 13, color: '#D7FFF8' },
    { id: 14, color: '#D7FFDD' },
    { id: 15, color: '#EDEDED' },


    { id: 16, color: '#FF0000' },
    { id: 17, color: '#F44336' },
    { id: 18, color: '#D8532B' },
    { id: 19, color: '#FFFF00' },
    { id: 20, color: '#FFDEA4' },
    { id: 21, color: '#FFBCB3' },
    { id: 22, color: '#FFBAEA' },
    { id: 23, color: '#C9BCFF' },

    { id: 24, color: '#004400' },
    { id: 25, color: '#4D7B4D' },
    { id: 26, color: '#00AA00' },
    { id: 27, color: '#00FF00' },
    { id: 28, color: '#AADDFF' },
    { id: 29, color: '#9FFFED' },
    { id: 30, color: '#A7FFB2' },
    { id: 31, color: '#D7D7D7' },
];

// **********************************************************************
// FARENE ZNACENIE
// **********************************************************************
global.colors = ['#DDDDDD', '#FFDDDD', '#DDFFDD', '#FFFFDD', '#DDDDFF', '#DDFFFF', '#FFDDFF'];
global.colors_dark = ['#DDDDDD', '#FF0000', '#00AA00', '#FFFF00', '#0000FF', '#00FFFF', '#FF00FF'];

// ******************************************************************************************
// MANUAL TYPES
// ******************************************************************************************
global.manual_type_chapter = 1;
global.manual_type_page = 2;
global.manual_type_text = 3;
global.manual_type_image = 4;
global.manual_type_notify = 5;
global.manual_type_tip = 6;
global.manual_type_banner = 7;
global.manual_type_line = 8;
global.manual_type_arrow = 9;
global.manual_type_spacer = 10;
global.manual_type_anchor = 11;
global.manual_type_block = 12;

/*
<div style={{ ...styles.Block, height: height, alignItems: 'flex-start', justifyContent: 'flex-start', fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundColor: global.theme_white }} className='container'>
</div>

*/