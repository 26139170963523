import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { Debug, DialogContent, GetLanguage, GetManualText, Loading, SaveLanguage } from './items';
import { styles } from './styles';
import './items.css';

// IMAGES
import no_image from './react/app/no_image.jpg';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ManualItemAnchor, ManualItemArrow, ManualItemBanner, ManualItemImage, ManualItemLine, ManualItemNotify, ManualItemSpacer, ManualItemText, ManualItemTip } from './Page';


export const Guide = () => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(true);

    // PROJECT
    const [project, setProject] = useState([]);
    const [language, setLanguage] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [theme, setTheme] = useState([]);
    const [pages, setPages] = useState([]);


    // CONTENT - OBSAH
    const [showContent, setShowContent] = useState(false);

    // INTRO PAGE
    const [page, setPage] = useState([]);
    const [items, setItems] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const offset = 20; // medzera medzi prvkami

    const imageWidth = isLarge ? 800 : isMedium ? 600 : 320; // max. velkost obrázkov
    const imageHeight = isLarge ? 450 : isMedium ? 350 : 300;
    const bannerWidth = isLarge ? 800 : isMedium ? 600 : 320; // max. velkost bannerov
    const bannerHeight = isLarge ? 500 : isMedium ? 400 : 300;


    let params = useParams();
    var lang = GetLanguage(0);
    var started = false;

    useEffect(() => {

        if (started == false) {

            started = true;

            db_get();
        }

    }, [])

    const db_get = async () => {
        setBusy(true);
        setItems(false);

        try {
            const response = await fetch(
                global.db_url + 'project', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    project_tid: params.project,
                    language_tid: params.language
                })
            })

            const json = await response.json();

            Debug(json);
            if (json.ok > 0) {
                setProject(json.project);
                setLanguage(json.language);
                setChapters(json.chapters);
                setTheme(json.theme);
                setPages(json.pages);
                setPage(json.page);

                var tmp = json.items;
                if (tmp != false) {
                    tmp.forEach(item => {
                        item.json = JSON.parse(item.settings);
                    });

                    setItems(tmp);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }


    const DialogContentResult = (typ, page) => {

        setShowContent(false);
    }

    const GoSupport = () => {
        let link = '/guide/support/' + project.tid + '/' + language.tid;
        navigate(link, { replace: true });
    }

    const GetHtml = (item) => {
        let json = JSON.parse(item.settings);
        var list_type = 0;
        if (json != null) {
            list_type = json.list_type
        }

        var link = '';
        if (item.link_chapter_id > 0) {

            let p = pages.find(x => x.id == item.link_chapter_id);

            let project_tid = project.tid;
            let language_tid = language.tid;
            let page_tid = p.tid;
            link = global.web + '/guide/' + project_tid + '/' + language_tid + '/' + page_tid;
        }

        let html = GetManualText(item.label, item.text, item.html, theme, list_type, link);
        return html;
    }

    return (
        <div style={{ ...styles.BlockCenter, width: isSmall ? '95%' : '100%', height: height, backgroundColor: theme.background_page, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container' >
            <div style={{ ...styles.Block, backgroundColor: theme.background_intro, paddingTop: 30, paddingBottom: 30 }}>
                <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft }}>
                            <p style={{ ...styles.TextLarge, marginTop: 0, color: theme.color_text, fontWeight: '500', fontFamily: 'noto+sans' }}>{project.name}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 3, color: theme.color_text, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.guide}</p>
                        </div>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRight, paddingTop: 30 }}>
                            <div onClick={() => GoSupport()} style={{ cursor: 'pointer' }}>
                                <p style={{ ...styles.TextTiny, marginTop: 3, color: theme.color_text, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.support}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ ...styles.Block, backgroundColor: theme.background_content, paddingTop: 30, paddingBottom: 30 }}>
                <div style={{ ...styles.Block, maxWidth: global.maxWidth }}>
                    <div style={{ ...styles.Block }}>
                        {project.logo != undefined ?
                            <img src={project.logo != '' ? global.web_images + '/' + project.logo : no_image} style={{ width: '100%', height: '100%', maxWidth: 150, maxHeight: 150, objectFit: 'contain' }}></img>
                            : null}
                        <p style={{ ...styles.TextXXLarge, marginTop: 20, color: theme.color_text, fontWeight: '600' }}>{project.name}</p>
                        <p style={{ ...styles.TextNormal, marginTop: 5, color: theme.color_text, fontWeight: '400' }}>{language.guide}</p>
                    </div>
                </div>
            </div>
            <div style={{ ...styles.Block, marginTop: 30, maxWidth: global.max_screen }}>
                {language.content != undefined ?
                    <div onClick={() => setShowContent(true)} style={{ ...styles.BlockRowRaw, cursor: 'pointer' }}>
                        <FontAwesomeIcon style={{ height: 22, marginTop: 5, color: theme.color_link }} icon={faFolderOpen} />
                        <p style={{ ...styles.TextLarge, marginLeft: 10, color: theme.color_link, fontWeight: '400' }}>{language.content}</p>
                    </div>
                    : null}
            </div>
            <div style={{ ...styles.Block, marginTop: 30, maxWidth: global.max_content }}>
                <div style={{ ...styles.BlockLeft }}>
                    <div style={{ ...styles.BlockLeft, marginTop: 25, paddingBottom: 80 }}>
                        {items != false ? items.map((item, index) => (
                            <div key={item.id} style={{ ...styles.Block, marginTop: offset, marginBottom: offset }}>
                                {parseInt(item.typ) == global.manual_type_text ?
                                    <ManualItemText link={item.link_chapter_id} link_text={item.html} anchor={item.anchor_id} tiny={params.tiny == undefined || params.tiny == 0 ? 0 : 1} theme={theme} html={GetHtml(item, params.tiny == undefined || params.tiny == 0 ? 0 : params.tiny, item.anchor_id)} center={item.json != null ? item.json.list_type == 3 ? true : false : false} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_image ?
                                    <ManualItemImage width={imageWidth} height={imageHeight} render={true} theme={theme} item={item} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_notify ?
                                    <ManualItemNotify theme={theme} html={GetHtml(item, params.tiny == undefined || params.tiny == 0 ? 0 : params.tiny)} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_tip ?
                                    <ManualItemTip theme={theme} html={GetHtml(item, params.tiny == undefined || params.tiny == 0 ? 0 : params.tiny)} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_banner ?
                                    <ManualItemBanner width={bannerWidth} height={bannerHeight} render={true} theme={theme} item={item} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_line ?
                                    <ManualItemLine theme={theme} item={item} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_arrow ?
                                    <ManualItemArrow theme={theme} item={item} type={item.json != null ? item.json.list_type : 0} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_spacer ?
                                    <ManualItemSpacer theme={theme} item={item} type={item.json != null ? item.json.list_type == false ? 20 : item.json.list_type : 20} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_anchor ?
                                    <ManualItemAnchor theme={theme} item={item} type={item.json != null ? item.json.list_type : 'empty'} />
                                    : null}
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>

            {showContent == true ?
                <DialogContent lang={lang} project={project} language={language} chapters={chapters} theme={theme} func={DialogContentResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading center={true} />
                : null}

            {isBusy == true ?
                <Loading center={true} />
                : null}
        </div>

    );
}


