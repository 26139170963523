// **********************************************************************
// SK - LANGUAGE
// **********************************************************************

export const sk = {
    title: 'Vision',
    language: 'SK',

}

