import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Backdrop, Button, IconButton, Paper, useMediaQuery } from '@mui/material';
import { Debug, DialogContent, GetLanguage, GetManualText, Loading } from './items';
import { styles } from './styles';
import './items.css';

// IMAGES
import no_image from './react/app/no_image.jpg';
import { faAngleLeft, faAngleRight, faArrowDown, faDownLong, faFolderOpen, faInfoCircle, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const Page = () => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    // PROJECT
    const [project, setProject] = useState([]);
    const [language, setLanguage] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [chapter, setChapter] = useState([]);
    const [pages, setPages] = useState([]);
    const [theme, setTheme] = useState([]);
    const [page, setPage] = useState([]);
    const [items, setItems] = useState(false);
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);

    // CONTENT - OBSAH
    const [showContent, setShowContent] = useState(false);

    // ODKAZ V OKNE
    const [showDialog, setShowDialog] = useState(false);
    const [anchor, setAnchor] = useState(0);
    const [subAnchor, setSubAnchor] = useState(0);
    const [subpage, setSubPage] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const imageWidth = isLarge ? 800 : isMedium ? 600 : width - 20; // max. velkost obrázkov
    const imageHeight = isLarge ? 450 : isMedium ? 350 : 300;
    const bannerWidth = isLarge ? 800 : isMedium ? 600 : width - 20; // max. velkost bannerov
    const bannerHeight = isLarge ? 500 : isMedium ? 400 : 300;


    let params = useParams();
    var lang = GetLanguage(0);
    var started = false;
    var block_start = false;
    var block_color = '#F3F3F3';


    useEffect(() => {

        if (started == false) {

            started = true;

            db_get();
        }

    }, [params.page])

    const db_get = async () => {
        setItems(false);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    project_tid: params.project,
                    language_tid: params.language,
                    page_tid: params.page,
                    anchor_id: params.anchor == undefined ? 0 : params.anchor
                })
            })

            const json = await response.json();
            Debug('json');
            Debug(json);

            if (json.ok > 0) {
                setProject(json.project);
                setLanguage(json.language);
                setChapters(json.chapters);
                setChapter(json.chapter);
                setPages(json.pages);
                setTheme(json.theme);
                setPage(json.page);
                setPrev(json.prev);
                setNext(json.next);

                var tmp = json.items;
                if (tmp != false) {
                    tmp.forEach(item => {
                        item.json = JSON.parse(item.settings);
                    });
                    setItems(tmp);
                }
                if (json.anchor_tid == '') {
                    //GoToStart();
                } else {
                    GoToAnchor(json.anchor_tid);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const DialogContentResult = (typ, value) => {

        setShowContent(false);
    }

    const GoHome = () => {
        let link = '/guide/' + project.tid + '/' + language.tid;
        navigate(link, { replace: true });
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 250);
    }

    const GoToAnchor = (anchor) => {
        Debug('GOTO ANCHOR');
        Debug(anchor);
        setTimeout(() => {
            const element = document.getElementById(anchor);

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 400);
    }

    const PrevPage = () => {
        let link = '/guide/' + project.tid + '/' + language.tid + '/' + prev.tid;
        Debug(link);
        navigate(link, { replace: true });
    }

    const NextPage = () => {
        let link = '/guide/' + project.tid + '/' + language.tid + '/' + next.tid;
        Debug(link);
        navigate(link, { replace: true });
    }


    const GetHtml = (item, tiny, anchor) => {
        let json = JSON.parse(item.settings);
        var list_type = 0;
        if (json != null) {
            list_type = json.list_type
        }

        var link = '';
        if (item.link_chapter_id > 0) {

            let p = pages.find(x => x.id == item.link_chapter_id);
            if (p != undefined) {
                let project_tid = project.tid;
                let language_tid = language.tid;
                let page_tid = p.tid;

                if (anchor == 0) {
                    link = global.web + '/guide/' + project_tid + '/' + language_tid + '/' + page_tid;
                    link += '/' + tiny;
                    /*
                    if (parseInt(anchor) > 0) {
                        link += '/' + anchor;
                    }
                    */
                }

                Debug('LINK', 'red');
                Debug(link);
                Debug(anchor);
            }

        }

        let html = GetManualText(item.label, item.text, item.html, theme, list_type, link);

        return html;
    }

    const GoSupport = () => {
        let link = '/guide/support/' + project.tid + '/' + language.tid;
        navigate(link, { replace: true });
    }

    const TextResult = (typ, link, anchor) => {
        if (typ == true) {
            let page = pages.find(x => x.id == link);
            if (page != undefined) {
                let page_tid = page.tid;

                setSubPage(page_tid);
                setSubAnchor(anchor);
                setShowDialog(true);
            }
        }
    }

    return (
        <div style={{ ...styles.BlockCenter, width: isSmall ? '98%' : '100%', height: height, backgroundColor: theme.background_page, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container' >
            {params.tiny == undefined || params.tiny == 0 ?
                <div id={'id_start'} style={{ ...styles.Block, backgroundColor: theme.background_intro, paddingTop: 30, paddingBottom: 30 }}>
                    <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockLeft }}>
                                <div onClick={() => GoHome()} style={{ cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextLarge, marginTop: 0, color: theme.color_text, fontWeight: '500', fontFamily: 'noto+sans' }}>{project.name}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 3, color: theme.color_text, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.guide}</p>
                                </div>
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRight, paddingTop: 30 }}>
                                <div onClick={() => GoSupport()} style={{ cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, marginTop: 3, color: theme.color_text, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.support}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            <div style={{ ...styles.Block, marginTop: 30, maxWidth: global.max_content }}>
                <div style={{ ...isSmall ? styles.Block : styles.BlockLeft }}>
                    {params.tiny == undefined || params.tiny == 0 ?
                        <div onClick={() => setShowContent(true)} style={{ ...styles.BlockRowRaw, cursor: 'pointer' }}>
                            {isBusy == false || items.length > 0 ?
                                <FontAwesomeIcon style={{ height: 22, marginTop: 5, color: theme.color_link }} icon={faFolderOpen} />
                                : null}
                            <p style={{ ...styles.TextLarge, marginLeft: 10, color: theme.color_link, fontWeight: '400' }}>{language.content}</p>
                        </div>
                        : null}
                    <div style={{ ...styles.BlockLeft, marginTop: params.tiny == undefined || params.tiny == 0 ? 25 : 0 }}>
                        <p style={{ ...styles.TextTiny, marginBottom: 5, color: theme.color_text, fontWeight: '400', fontFamily: 'noto+sans' }}>{chapter.label}</p>
                        <div style={{ ...styles.Block, height: 1, backgroundColor: theme.color_line }}></div>
                        <p style={{ ...styles.TextXLarge, marginTop: 0, color: theme.color_text, fontWeight: '600', fontFamily: 'noto+sans' }}>{page.label}</p>
                    </div>
                    <div style={{ ...styles.BlockLeft, marginTop: 25, paddingBottom: 40 }}>
                        {items != false ? items.map((item, index) => (
                            <div style={{ ...styles.Block }}>
                                <div style={{ display: 'none' }} >
                                    {parseInt(item.typ) == global.manual_type_block ? item.json != null ? item.json.list_type == 0 ? block_start = true : block_start = false : null : null}
                                    {parseInt(item.typ) == global.manual_type_block ? item.json != null ? item.json.list_type == 0 ? item.json.color == undefined ? block_color = theme.background_block : block_color = item.json.color : block_color = '#f3f3f3' : null : null}
                                </div>
                                <div key={item.id} style={{ ...styles.Block, backgroundColor: block_start == true ? block_color : undefined, paddingTop: parseInt(theme.offset), paddingBottom: parseInt(theme.offset) }}>
                                    <div key={item.id} style={{ ...styles.Block, width: '98%' }}>
                                        {parseInt(item.typ) == global.manual_type_text ?
                                            <ManualItemText link={item.link_chapter_id} link_text={item.html} anchor={item.anchor_id} tiny={params.tiny == undefined || params.tiny == 0 ? 0 : 1} theme={theme} html={GetHtml(item, params.tiny == undefined ? 0 : params.tiny, item.anchor_id)} center={item.json != null ? item.json.list_type == 3 ? true : false : false} func={TextResult.bind(this)} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_image ?
                                            <ManualItemImage width={imageWidth} height={imageHeight} render={true} theme={theme} item={item} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_notify ?
                                            <ManualItemNotify theme={theme} html={GetHtml(item, params.tiny == undefined || params.tiny == 0 ? 0 : params.tiny)} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_tip ?
                                            <ManualItemTip theme={theme} html={GetHtml(item, params.tiny == undefined || params.tiny == 0 ? 0 : params.tiny)} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_banner ?
                                            <ManualItemBanner width={bannerWidth} height={bannerHeight} render={true} theme={theme} item={item} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_line ?
                                            <ManualItemLine theme={theme} item={item} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_arrow ?
                                            <ManualItemArrow theme={theme} item={item} type={item.json != null ? item.json.list_type : 0} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_spacer ?
                                            <ManualItemSpacer theme={theme} item={item} type={item.json != null ? item.json.list_type == false ? 20 : item.json.list_type : 20} />
                                            : null}
                                        {parseInt(item.typ) == global.manual_type_anchor ?
                                            <ManualItemAnchor theme={theme} item={item} type={item.json != null ? item.json.list_type : 'empty'} />
                                            : null}
                                    </div>
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>
            {params.tiny == undefined || params.tiny == 0 ?
                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 70, borderTop: '1px solid ' + theme.background_intro }}>
                    <div style={{ ...styles.Block, maxWidth: global.max_screen }}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft }}>
                                { // PREDCHADZAJUCE
                                    prev != false ?
                                        <div onClick={() => PrevPage()} style={{ ...styles.BlockLeft, width: undefined, cursor: 'pointer' }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <FontAwesomeIcon style={{ height: 22, color: theme.color_link }} icon={faAngleLeft} />
                                                <p style={{ ...styles.TextTiny, marginLeft: 8, marginTop: 2, color: global.theme_dark_gray, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.prev}</p>
                                            </div>

                                            <p style={{ ...styles.TextSmall, marginLeft: 22, marginTop: 8, color: theme.color_text, fontWeight: '500', fontFamily: 'noto+sans' }}>{prev.label}</p>
                                        </div>
                                        :
                                        next != false ?
                                            <div onClick={() => setShowContent(true)} style={{ ...styles.BlockRight, width: undefined, cursor: 'pointer' }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: theme.color_link }} icon={faFolderOpen} />
                                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginTop: 0, color: theme.color_link, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.content}</p>
                                                </div>
                                            </div>
                                            : null}
                            </div>
                            <div style={{ ...styles.BlockRight }}>
                                { // NASLEDUJUCE
                                    next != false ?
                                        <div onClick={() => NextPage()} style={{ ...styles.BlockRight, width: undefined, cursor: 'pointer' }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <p style={{ ...styles.TextTiny, marginRight: 8, marginTop: 2, color: global.theme_dark_gray, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.next}</p>
                                                <FontAwesomeIcon style={{ height: 22, color: theme.color_link }} icon={faAngleRight} />
                                            </div>
                                            <p style={{ ...styles.TextSmall, marginRight: 22, marginTop: 8, color: theme.color_text, fontWeight: '500', fontFamily: 'noto+sans' }}>{next.label}</p>
                                        </div>
                                        :
                                        <div onClick={() => setShowContent(true)} style={{ ...styles.BlockRight, width: undefined, cursor: 'pointer' }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                {isBusy == false || items.length > 0 ?
                                                    <FontAwesomeIcon style={{ height: 16, color: theme.color_link }} icon={faFolderOpen} />
                                                    : null}
                                                <p style={{ ...styles.TextTiny, marginLeft: 8, marginTop: 0, color: theme.color_link, fontWeight: '400', fontFamily: 'noto+sans' }}>{language.content}</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                : null}

            {showDialog == true ?
                <SubPage project={project.tid} language={language.tid} page={subpage} anchor={subAnchor} func={() => setShowDialog(false)} />

                : null}
            {showContent == true ?
                <DialogContent lang={lang} project={project} language={language} chapters={chapters} theme={theme} func={DialogContentResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading center={true} />
                : null}

        </div >
    );
}

export const ManualItemText = (props) => {

    let { func } = props;

    useEffect(() => {
        Debug(props);
        return () => {
        };
    }, []);

    return (
        <div style={{ ...styles.Block }}>
            <div style={{ ...styles.TextSmall, fontSize: parseInt(props.theme.text_size), width: '100%', textAlign: props.center == true ? 'center' : 'left' }} dangerouslySetInnerHTML={{ __html: props.html }} />
            {parseInt(props.anchor) > 0 ?
                <div onClick={() => func(true, props.link, props.anchor)} style={{ ...styles.BlockLeft, cursor: 'pointer' }}>
                    <p style={{ ...styles.TextTiny, color: props.theme.color_link }}>{props.link_text}</p>
                </div>
                : null}
        </div>
    )
}


export const ManualItemNotify = (props) => {
    /*
        USAGE:
                <ManualItemNotify theme={theme} html={html} />
    */
    return (
        <div style={{ ...styles.BlockRow, backgroundColor: props.theme.background_content }}>
            <div style={{ ...styles.BlockCenter, borderLeft: '5px solid ' + props.theme.color_notice, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: 36, paddingTop: 2 }}>
                        <FontAwesomeIcon style={{ height: 20, color: props.theme.color_notice }} icon={faTriangleExclamation} />
                    </div>
                    <div style={{ ...styles.TextSmall, width: '95%', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: props.html }} />
                </div>
            </div>
        </div>
    )
}


export const ManualItemTip = (props) => {
    /*
        USAGE:
                <ManualItemTip theme={theme} html={html} />
    */
    return (
        <div style={{ ...styles.BlockRow, backgroundColor: props.theme.background_tip }}>
            <div style={{ ...styles.BlockCenter, borderLeft: '5px solid ' + props.theme.color_tip, paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: 36, paddingTop: 3 }}>
                        <FontAwesomeIcon style={{ height: 20, color: props.theme.color_tip }} icon={faInfoCircle} />
                    </div>
                    <div style={{ ...styles.TextSmall, width: '95%', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: props.html }} />
                </div>
            </div>
        </div>
    )
}


export const ManualItemImage = (props) => {
    /*
        USAGE:
                <ManualItemImage theme={theme} item={item}/>
    */

    const [coef, setCoef] = useState(1);

    const offset = 5;
    const titleHeight = 40;
    const titleWidth = 100;

    const image_width = props.width - titleWidth;
    const image_height = props.height;

    useEffect(() => {

        let w = parseInt(props.item.image_width);
        let h = parseInt(props.item.image_height);

        if (w > h) {
            // NA SIRKU
            let c = image_width / 1300;
            setCoef(c)

        } else {
            // NA VYSKU
            let c = image_height / 700;
            setCoef(c);
        }

        return () => {
        };
    }, []);

    return (
        <div style={{ ...props.render == true ? styles.BlockLeft : styles.Block, height: props.render == true ? undefined : 650 }}>
            <div style={{ ...styles.BlockRowRaw }}>
                <div style={{ ...styles.Block, width: parseInt(props.item.image_width) * coef }}>
                    {props.item.label.trim() != '' ?
                        <div style={{ ...styles.Block, height: titleHeight, justifyContent: 'flex-end' }}>
                            <p style={{ ...styles.TextTiny, fontSize: parseInt(props.theme.note_size), color: global.theme_dark_gray, marginBottom: offset }}>{props.item.label}</p>
                        </div>
                        : null}
                    <img src={props.item.image != '' ? global.web_images + '/' + props.item.image : no_image} style={{ width: props.item.image_width * coef, height: props.item.image_height * coef, maxWidth: image_width, maxHeight: image_height, objectFit: 'contain' }}></img>
                    {props.item.html.trim() != '' ?
                        <div style={{ ...styles.Block, height: titleHeight, justifyContent: 'flex-start' }}>
                            <p style={{ ...styles.TextTiny, fontSize: parseInt(props.theme.note_size), color: global.theme_dark_gray, marginTop: offset }}>{props.item.html}</p>
                        </div>
                        : null}
                </div>
                {props.item.text.trim() != '' ?
                    <div style={{ ...styles.Block, alignItems: 'flex-start', width: titleWidth, marginTop: props.item.label.trim() != '' ? titleHeight : 0, height: parseInt(props.item.image_height * coef) }}>
                        <p style={{ ...styles.TextTiny, fontSize: parseInt(props.theme.note_size), color: global.theme_dark_gray, marginLeft: offset, textAlign: 'left' }}>{props.item.text}</p>
                    </div>
                    : null}
            </div>
        </div>
    )
}


export const ManualItemBanner = (props) => {
    /*
        USAGE:
                <ManualItemBanner theme={theme} item={item}/>
    */

    const [coef, setCoef] = useState(1);


    useEffect(() => {

        return () => {
        };
    }, []);

    return (
        <div style={{ ...styles.Block }}>
            <img src={props.item.image != '' ? global.web_images + '/' + props.item.image : no_image} style={{ width: '100%', height: '100%', maxWidth: props.width, maxHeight: props.height, objectFit: 'contain' }}></img>
        </div>
    )
}


export const ManualItemLine = (props) => {
    /*
        USAGE:
                <ManualItemTip theme={theme} html={html} />
    */
    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray, height: 1 }}>
        </div>
    )
}


export const ManualItemArrow = (props) => {
    /*
        USAGE:
                <ManualItemTip theme={theme} html={html} />
    */
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    return (
        <div style={{ ...styles.Block }}>
            {isSmall ?
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: '50%' }}></div>
                    <FontAwesomeIcon style={{ height: props.theme.arrow_size, color: props.theme.color_arrow }} icon={faDownLong} />
                    <div style={{ ...styles.Block, width: '50%' }}></div>
                </div>
                :
                props.type == 'center' || props.type == 'false' ?
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.Block, width: '50%' }}></div>
                        <FontAwesomeIcon style={{ height: props.theme.arrow_size, color: props.theme.color_arrow }} icon={faDownLong} />
                        <div style={{ ...styles.Block, width: '50%' }}></div>
                    </div>
                    :
                    <div style={{ ...styles.BlockLeft }}>
                        <FontAwesomeIcon style={{ height: props.theme.arrow_size, color: props.theme.color_arrow, marginLeft: props.type }} icon={faDownLong} />
                    </div>
            }
        </div>
    )
}


export const ManualItemSpacer = (props) => {
    /*
        USAGE:
                <ManualItemTip theme={theme} html={html} />
    */

    let height = parseInt(props.type);
    return (
        <div style={{ ...styles.Block, height: height }}>
        </div>
    )
}

export const ManualItemAnchor = (props) => {
    /*
        USAGE:
                <ManualItemTip theme={theme} html={html} />
    */

    let anchor = props.type;

    return (
        <div id={anchor} style={{ ...styles.Block }}></div>
    )
}

// STRANKA V OKNE - AKO ODKAZ
export const SubPage = (props) => {

    const [isBusy, setBusy] = useState(false);

    // PROJECT
    const [project, setProject] = useState([]);
    const [language, setLanguage] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [chapter, setChapter] = useState([]);
    const [pages, setPages] = useState([]);
    const [theme, setTheme] = useState([]);
    const [page, setPage] = useState([]);
    const [items, setItems] = useState(false);
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const imageWidth = isLarge ? 800 : isMedium ? 600 : width - 20; // max. velkost obrázkov
    const imageHeight = isLarge ? 450 : isMedium ? 350 : 300;
    const bannerWidth = isLarge ? 800 : isMedium ? 600 : width - 20; // max. velkost bannerov
    const bannerHeight = isLarge ? 500 : isMedium ? 400 : 300;

    const dialogWidth = width > global.max_content + 20 ? global.max_content + 20 : width;
    const dialogHeight = height;

    let params = useParams();
    var lang = GetLanguage(0);
    var started = false;
    let { func } = props;

    useEffect(() => {

        if (started == false) {
            started = true;

            Debug(props);
            db_get();
        }

    }, [params.page])

    const db_get = async () => {
        setItems(false);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'page', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    project_tid: props.project,
                    language_tid: props.language,
                    page_tid: props.page,
                    anchor_id: props.anchor == undefined ? 0 : props.anchor
                })
            })

            const json = await response.json();
            Debug('json', 'blue');
            Debug(json);

            if (json.ok > 0) {
                setProject(json.project);
                setLanguage(json.language);
                setChapters(json.chapters);
                setChapter(json.chapter);
                setPages(json.pages);
                setTheme(json.theme);
                setPage(json.page);
                setPrev(json.prev);
                setNext(json.next);

                var tmp = json.items;
                if (tmp != false) {
                    tmp.forEach(item => {
                        item.json = JSON.parse(item.settings);
                    });
                    setItems(tmp);
                }
                if (json.anchor_tid == '') {
                    //GoToStart();
                } else {
                    GoToAnchor(json.anchor_tid);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }

    }

    const GoToAnchor = (anchor) => {
        Debug('GOTO ANCHOR');
        Debug(anchor);
        setTimeout(() => {
            const element = document.getElementById(anchor);

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 400);
    }

    const GetHtml = (item, tiny, anchor) => {
        let json = JSON.parse(item.settings);
        var list_type = 0;
        if (json != null) {
            list_type = json.list_type
        }

        var link = '';
        if (item.link_chapter_id > 0) {

            let p = pages.find(x => x.id == item.link_chapter_id);

            let project_tid = project.tid;
            let language_tid = language.tid;
            let page_tid = p.tid;

            /*
            DEAKTIVOVANIE LINKU V DIALOGOVOM OKNE
            if (anchor == 0) {
                link = global.web + '/guide/' + project_tid + '/' + language_tid + '/' + page_tid;
                link += '/' + tiny;
            }
            */
        }

        let html = GetManualText(item.label, item.text, item.html, theme, list_type, link);

        return html;
    }

    return (
        <Backdrop open={true} style={{ backgroundColor: '#00000040' }} >
            <Paper elevation={global.menu_elevation} style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight, backgroundColor: theme.background_content, borderRadius: isSmall ? 0 : global.radius }}>
                <div style={{ ...styles.Block, height: 50, background: global.theme_light_gray, borderTopLeftRadius: isSmall ? 0 : global.radius, borderTopRightRadius: isSmall ? 0 : global.radius }}>
                    <div style={{ ...styles.BlockRight }}>
                        <IconButton onClick={() => func(false)} style={{ ...styles.ButtonIcon, marginRight: 20, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 12, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, maxWidth: isSmall ? width - 10 : global.max_content, height: dialogHeight - 120, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container' >
                    <div style={{ ...styles.BlockLeft, marginTop: params.tiny == undefined || params.tiny == 0 ? 25 : 0 }}>
                        <p style={{ ...styles.TextTiny, marginBottom: 5, color: theme.color_text, fontWeight: '400', fontFamily: 'noto+sans' }}>{chapter.label}</p>
                        <div style={{ ...styles.Block, height: 1, backgroundColor: theme.color_line }}></div>
                        <p style={{ ...styles.TextXLarge, marginTop: 0, color: theme.color_text, fontWeight: '600', fontFamily: 'noto+sans' }}>{page.label}</p>
                    </div>
                    <div style={{ ...styles.BlockLeft, marginTop: 25, paddingBottom: 40 }}>
                        {items != false ? items.map((item, index) => (
                            <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(theme.offset), marginBottom: parseInt(theme.offset) }}>

                                {parseInt(item.typ) == global.manual_type_text ?
                                    <ManualItemText link={0} anchor={0} tiny={params.tiny == undefined || params.tiny == 0 ? 0 : 1} theme={theme} html={GetHtml(item, params.tiny == undefined ? 0 : params.tiny, item.anchor_id)} center={item.json != null ? item.json.list_type == 3 ? true : false : false} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_image ?
                                    <ManualItemImage width={imageWidth} height={imageHeight} render={true} theme={theme} item={item} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_notify ?
                                    <ManualItemNotify theme={theme} html={GetHtml(item, params.tiny == undefined || params.tiny == 0 ? 0 : params.tiny)} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_tip ?
                                    <ManualItemTip theme={theme} html={GetHtml(item, params.tiny == undefined || params.tiny == 0 ? 0 : params.tiny)} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_banner ?
                                    <ManualItemBanner width={bannerWidth} height={bannerHeight} render={true} theme={theme} item={item} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_line ?
                                    <ManualItemLine theme={theme} item={item} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_arrow ?
                                    <ManualItemArrow theme={theme} item={item} type={item.json != null ? item.json.list_type : 0} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_spacer ?
                                    <ManualItemSpacer theme={theme} item={item} type={item.json != null ? item.json.list_type == false ? 20 : item.json.list_type : 20} />
                                    : null}
                                {parseInt(item.typ) == global.manual_type_anchor ?
                                    <ManualItemAnchor theme={theme} item={item} type={item.json != null ? item.json.list_type : 'empty'} />
                                    : null}
                            </div>
                        )) : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockLeft, width: '96%', height: 70, justifyContent: 'center' }}>
                    <Button onClick={() => func(false)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_white, width: 200, border: '1px solid #B0B0B0' }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                        <p style={{ ...styles.TextTiny, letterSpacing: 0 }}>{language.prev}</p>
                    </Button>
                </div>

                {isBusy == true ?
                    <Loading center={true} />
                    : null}

            </Paper>
        </Backdrop >
    );
}