import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren, redirect } from "react-router-dom";
import { sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faArrowLeft, faEye, faCheckCircle, faWarning, faMinus, faEnvelope, faPaperPlane, faInfo } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, Drawer, FormControlLabel, InputAdornment, Slide } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import { styles } from './styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import './items.css';



/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/


export const Debug = (data, color, typ) => {
    /*
        USAGE:
        ---------------------------------------
        Debug('text');
        Debug('text', 'gray', 'info');
        Debug('text', 'yellow', 'debug');
        Debug('text', 'red', 'warn');

    */

    if (global.testing == true) {
        if (color == undefined && typ == undefined) {
            console.log(data);
        } else {
            if (typ == 'warn') {
                console.warn('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'info') {
                console.info('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else if (typ == 'debug') {
                console.debug('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            } else {
                console.log('%c' + data, color == undefined ? 'color: white' : 'color:' + color);
            }
        }
    }
}



export const CopyData = (source, dest, exclude) => {
    // SKOPIROVANIE VSYTKYCH KEYS Z OBJEKTU - source DO OBJEKTU - dest
    // exclude - array[id, ...] - ktory keys nebudú skopírované

    var keys = Object.keys(source);
    keys.forEach(key => {
        if (!exclude.includes(key)) {
            dest[key] = source[key];
        }
    });
}

export function IsEmpty(data) {
    // kontrola ci je retazec prázdny
    if (data.trim() == '') {
        return true;
    } else {
        return false;
    }

}

export function notNumber(data) {
    // nie je císlo ?
    var number = data.trim().replaceAll(' ', '');
    number = number.replaceAll(',', '.');

    if (number != '') {
        if (isNaN(number)) {
            return true
        } else {
            return false;
        }
    } else {
        // prázdny retazec
        return true;
    }
}

export function nonEmail(mail) {
    // kontrola správnosti e-mailovej adresy - ak je chyba vráti -> true
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (false)
    }
    return (true);
};

export function strShuffle(s) {
    // tvorba náhodného kluca 
    var arr = s.split('');

    arr.sort(function () {
        return 0.5 - Math.random();
    });
    s = arr.join('');
    return s;
}

export function strShuffleCode(key, pass) {
    // tvorba kluca na základe hesla
    // key - kodovaci retazec
    // pass - heslo k zakodovaniu

    var n = 0;
    var sume = 0;
    while (n < pass.length) {
        sume += parseInt(pass.charCodeAt(n));
        n++;
    }
    sume = sume % 10;
    var offset = (sume * 4) % key.length;
    var code = key.substring(offset, key.length) + key.substring(0, offset);
    var arr = code.split('');

    n = 0;
    arr.sort(function () {
        let rand = pass.charCodeAt(n) % 10;
        n++;
        if (n == pass.length) {
            rand = sume;
            n = 0;
        }
        return rand - 5;
    });

    var str = arr.join('');
    return str;
}

export function GetTid(text) {
    var txt = '';
    if (text != null && text != undefined) {

        txt = text.trim();
        txt = text.toLowerCase();
        txt = txt.replaceAll('    ', '-');
        txt = txt.replaceAll('   ', '-');
        txt = txt.replaceAll('  ', '-');
        txt = txt.replaceAll(' ', '-');
        txt = txt.replaceAll('+', '');
        txt = txt.replaceAll('/', '');
        txt = txt.replaceAll('@', '');
        txt = txt.replaceAll('#', '');
        txt = txt.replaceAll('$', '');
        txt = txt.replaceAll('*', '');
        txt = txt.replaceAll('=', '');
        txt = txt.replaceAll(',', '');
        txt = txt.replaceAll('.', '');
        txt = txt.replaceAll('_', '-');

        txt = txt.replaceAll('ľ', 'l');
        txt = txt.replaceAll('š', 's');
        txt = txt.replaceAll('č', 'c');
        txt = txt.replaceAll('ť', 't');
        txt = txt.replaceAll('ž', 'z');
        txt = txt.replaceAll('ý', 'y');
        txt = txt.replaceAll('á', 'a');
        txt = txt.replaceAll('í', 'i');
        txt = txt.replaceAll('é', 'e');
        txt = txt.replaceAll('ú', 'u');
        txt = txt.replaceAll('ä', 'a');
        txt = txt.replaceAll('ô', 'o');
        txt = txt.replaceAll('ň', 'n');
        txt = txt.replaceAll('ř', 'r');
        txt = txt.replaceAll('ó', 'o');
        txt = txt.replaceAll('ď', 'd');
    }

    return txt;
}

export function GetPrice(price) {
    var txt = price.toString().replace('.', ',');

    return txt;
}

export function ConvertToPrice(price) {
    var txt = price.toString().replace(',', '.');
    txt = txt.trim();
    if (txt == '') {
        txt = '0';
    }
    txt = parseFloat(txt).toFixed(2);

    return txt;
}


export const GetAge = (_date) => {
    var date = new Date(_date);
    var diff_ms = Date.now() - date.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const Today = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GetTodayDatum = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = d + '.' + m + '.' + y;

    return today;
}

export const GetTodayYearMonth = () => {
    var today = new Date();

    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m;

    return today;
}

export function AddDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function SubDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}


export const GetDateYear = (date) => {
    var today = new Date(date);
    var year = today.getFullYear();
    return year;
}

export const GetTodayYear = () => {
    var today = new Date();
    var year = today.getFullYear();
    return year;
}


export const GetTodayMonth = () => {
    var today = new Date();
    var month = today.getMonth();
    return month;
}

export const GetTodayTime = () => {
    var today = new Date();
    var h = String(today.getHours()).padStart(2, '0');
    var m = String(today.getMinutes()).padStart(2, '0');
    return h + ':' + m;
}


export const GetDatum = (months, str) => {
    var today = new Date(str);

    var d = String(today.getDate()).padStart(2, '0');
    var m = months[today.getMonth()].toLowerCase();
    var y = String(today.getFullYear());
    today = d + '.' + m + ' ' + y;

    return today;
}

export const GetDatum2 = (date) => {
    var datum = new Date(date);

    var d = String(datum.getDate()).padStart(2, '0');
    var m = String(datum.getMonth() + 1).padStart(2, '0');
    var y = String(datum.getFullYear());

    return d + '.' + m + '.' + y;
}

export const GetDate = (str) => {
    var today = new Date(str);

    var d = String(today.getDate());
    var m = String(today.getMonth() + 1);
    var y = String(today.getFullYear());
    today = d + '.' + m + '.' + y;

    return today;
}


export const GetDate2 = (str) => {
    var tmp = str.split('.');
    var date = null;
    if (tmp.length == 3) {
        var d = tmp[0].toString().padStart(2, '0');
        var m = tmp[1].toString().padStart(2, '0');;
        var y = tmp[2];
        date = y + '-' + m + '-' + d;
    }
    if (tmp.length == 2) {
        var m = tmp[0].toString().padStart(2, '0');;
        var y = tmp[1];
        date = y + '-' + m;
    }
    if (tmp.length == 1) {
        var y = tmp[0];
        date = y;
    }

    return date;
}

export const GetDate3 = (str) => {
    var today = new Date(str);

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

export const GetHours = (minutes) => {
    // vrati hodiny z minut
    var hour = parseInt(minutes / 60);
    var minute = minutes - (hour * 60);
    return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

export function UniqueID() {
    let s4 = () => { return Math.floor((1 + Math.random() * 0x10000)).toString(16).substring(1); }
    return (Date.now() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4());
}

/*
*
* =================================================================================================================
* 
* =================================================================================================================
*
*/

export const Init = () => {

}

// ********************************************************
// LANGUAGE
// ********************************************************

export const SaveLanguage = (lang) => {
    localStorage.setItem('vision-app-language', lang);
}

export const LoadLanguage = () => {
    var lang = localStorage.getItem('vision-app-language');
    if (lang != null) {
        var result = sk;

        if (lang == 0) {
            result = sk;
        }
        return result;
    } else {
        return sk;
    }
}


export const GetLanguage = (lang) => {
    var result = sk;
    if (lang == 0) {
        result = sk;
    }

    return result;
}

// ********************************************************
// LOGIN TOKEN
// ********************************************************
export const ResetLogin = () => {
    var data = { token: '', expire: 0 };
    SaveLogin(data);
}

export const LoadLogin = () => {
    var data = localStorage.getItem('Vision-app-login');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return { token: '', date: 0 };
    }
}

export const SaveLogin = (data) => {
    localStorage.setItem('Vision-app-login', JSON.stringify(data));
}
// ********************************************************

export const Loading = (props) => {
    /*
        USAGE: 
                <Loading offset={offset} center={true} />
    */
    const [width, setWidth] = useState(window.innerWidth);

    const logo = require('./react/app/logo_small.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <Paper elevation={3} style={{ ...styles.Block, marginLeft: (props.center == true) || (width <= global.max_screen) ? 0 : global.menu_width + (2 * props.offset == undefined ? 16 : props.offset), width: 75, height: 75, backgroundColor: global.theme_white, borderRadius: 40 }}>
                <img src={logo} style={{ marginTop: 10, width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_light }} size={70} />
            </Paper>
        </Backdrop>);
}


export const ShowOK = (props) => {
    /*
            USAGE:
            const [showOK, setShowOK] = useState(false);
            setShowOK(true);

            {showOK == true ?
                <ShowOK func={ShowOKPress.bind(this)} />
                : null}

            const ShowOKPress = (value) => {                
                setShowOK(false);
            }

            */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 1000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: width > global.max_screen ? props.center == true ? 0 : global.menu_width : 0 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 60, height: 60, backgroundColor: global.theme_darker_green, borderRadius: 40 }}>
                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_white }} icon={faCheck} />
            </Paper>
        </Backdrop>
    );
}


export const ShowError = (props) => {
    /*
            USAGE:
            const [showError, setShowError] = useState(false);
            setShowError(true);

            {showError == true ?
                <ShowError text={'Chyba'} func={ShowErrorPress.bind(this)} />
                : null}

            const ShowErrorPress = (value) => {                
                setShowError(false);
            }

            */

    const [width, setWidth] = useState(window.innerWidth);

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        const timer = setTimeout(() => {
            Press(false);
        }, 4000);

        return () => {
        };

    }, []);

    const Press = (item) => {
        func(item);
    }

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000', paddingLeft: width > global.max_screen ? props.center == true ? 0 : global.menu_width : 0 }}>
            <Paper elevation={6} style={{ ...styles.Block, marginLeft: 0, width: 320, height: 130, backgroundColor: global.theme_black, borderRadius: 16 }}>
                <div style={{ ...styles.Block, width: 40, height: 40, borderRadius: 20, backgroundColor: global.theme_dark_red }}>
                    <FontAwesomeIcon style={{ width: 30, height: 30, color: global.theme_white }} icon={faXmark} />
                </div>
                <div style={{ ...styles.Block, width: '95%' }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_white, marginTop: 15 }}>{props.text}</p>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const TextInput = (props) => {
    /* USAGE        
        
        const [userData, setUserData] = useState({mobil: ''});

        <TextInput required redraw enabled={true} lang={lang} value={userData.mobil} label={lang.mobil} func={(txt) => { userData.mobil = txt }} />

        required    -> text je vyzadovany
        phone       -> jedna sa o tel. cislo v medzinárodnom formate
        email       -> kontroluje správnost e-mailovej adresy
        enabled     -> editovanie true / false
        label       -> place holder
        redraw      -> text bude prekreslovaný pri zmene iných textových polí - setRedraw(!redraw)
        type        -> default = 'text', 'date'
        placeholder -> placeholder text
        money       -> zobrazi menu na konci textboxu
        width       -> širka boxu
        center      -> vycentrovať titulok aj box
        numeric     -> zadávanie len číslic [0-9]
        rows        -> pocet riadkov
    */


    const [text, setText] = useState(props.value);
    const [error, setError] = useState('');

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        Check(props.value);
        if (props.redraw != undefined) {
            setText(props.value);
        }

        return () => {
        };

    }, [props.value]);

    const ChangeText = (txt) => {
        if (props.numeric == true) {
            if (/^[0123456789]+$/.test(txt)) {
                setText(txt);
                setText(txt);
                Check(txt);
                func(txt);
            }
            if (txt == '') {
                setText(txt);
                setText(txt);
                Check(txt);
                func(txt);
            }
        } else {
            setText(txt);
            Check(txt);
            func(txt);
        }
    }

    const Check = (text) => {
        if (error != '') {
            setError('');
        }
        if (text != undefined) {
            if (props.phone) {
                if (text.trim() != '') {
                    if (text[0] != '+') {
                        setError(lang.format_error);
                    }
                }
            }

            if (props.email) {
                if (text.trim() != '') {
                    if (nonEmail(text.trim()) == true)
                        setError(lang.format_error);
                }
            }
        }
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block }}>
            <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{props.label}</p>

            <TextField
                inputRef={props.inputRef}
                value={text}
                //onBlur={() => Check(text)}
                onInput={e => ChangeText(e.target.value)}
                size="small"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                style={{ width: props.width == undefined ? '100%' : props.width }}
                placeholder={props.placeholder == undefined ? '' : props.placeholder}
                multiline={props.rows != undefined ? true : false}
                rows={props.rows != undefined ? props.rows : 1}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                    }
                }}
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                    },
                    fieldset: { borderWidth: props.enabled == false ? 0 : 1 }
                }}
                InputProps={{
                    endAdornment: props.money == true ? <InputAdornment position="end">
                        <p style={{ ...styles.TextSmall }}>{lang.money}</p>
                    </InputAdornment> : null,
                    style: { backgroundColor: props.enabled == false ? '#00000007' : global.theme_white, borderRadius: props.radius == undefined ? 20 : props.radius, color: global.theme_black },
                }}
                type={props.password == true ? 'password' : props.type != undefined ? props.type : 'text'}
                variant="outlined"
                disabled={props.enabled == false ? true : false}
                error={props.error == true ? true : error != '' ? true : props.required == true ? text == '' ? true : false : false}
                helperText={props.enabled == false ? '' : error != '' ? error : props.required == true ? text == '' ? lang.required : '' : ''}
            />
        </div>
    );
}


export const FormError = (props) => {
    const height = 48;
    return (
        props.error != '' ?
            <div style={{ ...styles.Block, height: height, backgroundColor: global.theme_light_red, borderRadius: 7 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>{props.error}</p>
                </div>
            </div>
            :
            <div style={{ ...styles.Block, height: height, borderRadius: 20, marginTop: 20 }}></div>

    );
}

export const FormOK = (props) => {
    const height = 40;
    return (
        props.text != '' ?
            <div style={{ ...styles.Block, height: height, backgroundColor: global.theme_darker_green, borderRadius: 20, marginTop: 20 }}>
                <div style={{ ...styles.BlockRowRaw }}>
                    <FontAwesomeIcon style={{ height: 18, color: global.theme_white }} icon={faInfoCircle} />
                    <p style={{ ...styles.TextSmall, color: global.theme_white, marginLeft: 10 }}>{props.text}</p>
                </div>
            </div>
            :
            <div style={{ ...styles.Block, height: height, borderRadius: 20, marginTop: 20 }}></div>

    );
}


export const DialogTemplate = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogTemplate lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogResult.bind(this)} />

            const DialogResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 700;
    const dialogHeight = 500;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Process = () => {

    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '95%', height: dialogHeight - global.dialog_bar_height - errorHeight - buttonHeight }}>
                        {/* BODY */}

                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <FormError error={error} />
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const DialogContent = (props) => {
    /*
            OBSAH - full screen
            
            USAGE:
            <DialogContent lang={lang} func={DialogContentResult.bind(this)} />

            const DialogContentResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);

    // ACCORDITIONS
    const [expandedID, setExpandedID] = useState(0);
    const [overID, setOverID] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const dialogWidth = isLarge ? 700 : isMedium ? 600 : width;
    const dialogHeight = isSmall ? height : 500;
    const dialogPadding = 20;
    const titleHeight = 80;

    const navigate = useNavigate();
    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const handleExpanded = (id) => {
        setExpandedID(id);
    }

    const Process = (page) => {

        let link = '/guide/' + props.project.tid + '/' + props.language.tid + '/' + page.tid;
        navigate(link, { replace: true });
        Press(false, page);
    }

    const Home = () => {

        let link = '/guide/' + props.project.tid + '/' + props.language.tid;
        navigate(link, { replace: true });
        Press(false);
    }

    return (

        <Backdrop open={true} style={{ backgroundColor: '#000000' }} >
            <Paper elevation={global.menu_elevation} style={{ ...styles.Block, width: dialogWidth, height: dialogHeight, backgroundColor: props.theme.background_content, borderRadius: isSmall ? 0 : global.radius }}>
                <div style={{ ...styles.BlockCenter, width: dialogWidth - dialogPadding, height: dialogHeight - dialogPadding }}>
                    <div style={{ ...styles.BlockRow, height: titleHeight }}>
                        <div style={{ ...styles.BlockLeft, height: titleHeight, justifyContent: 'flex-start' }}>
                            <p style={{ ...isSmall ? styles.TextNormal : styles.TextLarge, marginLeft: 10, color: props.theme.color_text, fontWeight: 'bold' }}>{props.project.name}</p>
                            <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, marginLeft: 10, marginTop: 5, color: props.theme.color_text, fontWeight: '400' }}>{props.language.guide}</p>
                        </div>
                        <div style={{ ...styles.BlockRight, width: titleHeight }}>
                            <IconButton onClick={() => Close()} style={{ width: 34, height: 34 }}>
                                <FontAwesomeIcon style={{ width: 12, color: props.theme.color_text }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - dialogPadding - titleHeight, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container' >
                        <div onClick={() => Home()} style={{ ...styles.BlockLeft, width: '85%', marginLeft: 25, marginBottom: 15, cursor: 'pointer' }}  >
                            <p style={{ ...styles.TextNormal, color: props.theme.color_link }}>{props.language.welcome}</p>
                        </div>

                        {props.chapters.map((item, index) => (
                            <Accordion key={item.id} elevation={0} style={{ ...styles.BlockLeft, width: '85%', backgroundColor: props.theme.background_content, borderTop: '1px solid #DDDDDD' }} expanded={item.id == expandedID ? true : false} onChange={() => handleExpanded(item.id)}>
                                <AccordionSummary style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...isSmall ? styles.TextSmall : styles.TextNormal, color: props.theme.color_text, textAlign: 'left' }}>{index + 1}.<span style={{ marginLeft: 13 }}>{item.label}</span></p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item.pages.map((page, page_index) => (
                                        <div key={page.id} onClick={() => Process(page)} style={{ ...styles.BlockLeft, marginBottom: isSmall ? 15 : 10, cursor: 'pointer' }} onMouseOver={() => setOverID(page.id)} onMouseOut={() => setOverID(0)} >
                                            <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: page.id == overID ? props.theme.color_link : props.theme.color_text, marginLeft: 30, textDecoration: page.id == overID ? 'underline' : 'none', textAlign: 'left' }}>{index + 1}.{page_index + 1}. {page.label}</p>
                                        </div>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}


export function GetManualText(label, text, html_text, theme, list, link) {
    // FORMATOVANIE textu na html
    /*
        label   -> nadpis
        text    -> text
        theme   -> farebná téma
        list    -> BOOL: urobý z textu zoznam
    */
    var html = '';

    var label_formated = label.replaceAll('##b', "</b>");
    label_formated = label_formated.replaceAll('##i', "</i>");
    label_formated = label_formated.replaceAll('#b', "<b>");
    label_formated = label_formated.replaceAll('#i', "<i>");

    var txt_formated = text.replaceAll(/[\r\n]/g, "</br>");
    txt_formated = txt_formated.replaceAll('##b', "</b>");
    txt_formated = txt_formated.replaceAll('##i', "</i>");
    txt_formated = txt_formated.replaceAll('#b', "<b>");
    txt_formated = txt_formated.replaceAll('#i', "<i>");

    var html_formated = html_text.replaceAll('##b', "</b>");
    html_formated = html_formated.replaceAll('##i', "</i>");
    html_formated = html_formated.replaceAll('#b', "<b>");
    html_formated = html_formated.replaceAll('#i', "<i>");

    if (label.trim() != '') {
        let font_size = list == 1 || list == 2 ? theme.text_size : theme.label_size;
        html = '<p style="margin: 0px; padding: 0px; color: ' + theme.color_text + '; font-size: ' + parseInt(font_size) + 'px">' + label_formated + '</p>';
    }

    if (text.trim() != '') {
        if (list == 1 || list == 2) {
            let txt_array = txt_formated.split('</br>');
            if (list == 1) {
                html += '<ul>';
            } else {
                html += '<ol>';
            }

            txt_array.forEach(element => {
                if (element.trim() != '') {
                    html += '<li style="margin-bottom:15px; color: ' + theme.color_text + '; font-size: ' + parseInt(theme.text_size) + 'px">' + element + '</li>';
                }
            });
            if (list == 1) {
                html += '</ul>';
            } else {
                html += '</ol>';
            }

        } else {
            html += '<p style="margin: 0px;margin-top: 10px; padding: 0px; color: ' + theme.color_text + '; font-size: ' + parseInt(theme.text_size) + 'px">' + txt_formated + '</p>';
        }
    }

    if (html_formated.trim() != '') {
        if (link.trim() != '') {
            //html += '</br>';
            html += '<a href="' + link + '" style="margin: 0px; padding: 0px; text-decoration: none; color: ' + theme.color_link + '; font-size: ' + parseInt(theme.text_size) + 'px">' + html_formated + '</a>';
        }
    }


    return html;
}

export const Line = (props) => {
    return (
        <div style={{ width: '100%', height: 1, backgroundColor: props.color != undefined ? props.color : global.theme_medium, marginTop: props.margin == undefined ? 10 : props.margin, marginBottom: props.margin == undefined ? 10 : props.margin }} />
    )
}


export const DialogInfo = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogInfo lang={lang} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={DialogResult.bind(this)} />

            const DialogResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 600;
    const dialogHeight = 210;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ) => {
        func(typ);
    }

    return (

        <Backdrop open={true} style={{ backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.theme_white }}>
                <div style={{ ...styles.Block, height: dialogHeight - buttonHeight }}>
                    {/* BODY */}
                    <FontAwesomeIcon style={{ height: 36, color: global.theme_darker_green }} icon={faCheckCircle} />
                    <p style={{ ...styles.TextSmall, marginTop: 15 }}>{props.message}</p>
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark }}>{'OK'}</Button>
                </div>
            </Paper>
        </Backdrop >
    );
}
